import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: "root",
})
export class AuthGuardMaintenance  {
  constructor(
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (environment.isMaintenanceOn) {
      // alert('This Site Is Still Under Maintenance')
      this.router.navigate(['/maintenance']);
      return false;
    } else {
      // this.router.navigate(['/home']);
      return true;
    }
  }
}
