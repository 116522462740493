import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';

platformBrowserDynamic().bootstrapModule(AppModule, {
  ngZoneEventCoalescing: true
})
  .catch(err => console.error(err));

if (environment.production) {
  enableProdMode(); //Disable Angular's development mode, which turns off assertions and other checks within the framework.
  if (window) {
    window.console.log = function () { };
    window.console.debug = function () { };
    window.console.info = function () { };
  }
}  