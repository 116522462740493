import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../shared/services/user/user.service';
import { User } from '../shared/services/user/user.model';
import { AuthNewService } from '../shared/services/auth/auth-new.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SeoService } from '../shared/services/seo/seo.service';

interface env {
  production: boolean,
  name: string
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.tw.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  appName = environment.app.name;
  appLogo = environment.app.logo;

  env$ = new BehaviorSubject<env | undefined>(undefined); //{production:boolean, name:string}
  isLoggedIn$: Observable<boolean> = this.userService.isLoggedIn();
  // isLoggedIn$: Observable<boolean> = this.auth.isAuthenticated$;
  userNotifCount$: Observable<number> = this.userService.userNotifCount();
  userServiceUser$: Observable<User> = this.userService.user$;
  // user$: Observable<User> = this.auth.getUser$();
  user$: Observable<User> = this.auth.user$;
  userNotif$ = this.userService.userNewNotif$;
  websocketConnected$ = this.userService.websockectConnected$;
  alive: boolean = true;
  showMenu: boolean = false;

  constructor(public auth: AuthNewService,
    public userService: UserService,
    private snackBar: MatSnackBar,
    private seo: SeoService,
  ) { }


  showNotifs() {

  }

}
