import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardMaintenance } from '../shared/services/auth/auth-guard-maintenance.service';
import { CanLoadAuthGuard } from '../shared/services/auth/can-load-auth.guard';
import { DonationPaymentUpdateCompleteComponent } from './routes/my-donation/donation-item/donation-payment-update-complete/donation-payment-update-complete.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // // { path: 'events', component: EventListComponent },
  // // { path: 'events/:event_id', component: EventDetailComponent },
  {
    path: 'home',
    loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuardMaintenance]
  },
  {
    path: 'events', loadChildren: () => import('./routes/pub-event/pub-event.module').then(m => m.PubEventModule),
    // canLoad: [CanLoadAuthGuard]
    canActivate: [AuthGuardMaintenance],
    data: { preload: true }
  },
  {
    path: 'orgs',
    loadChildren: () => import('./routes/pub-org/pub-org.module').then(m => m.PubOrgModule),
    canActivate: [AuthGuardMaintenance],
    data: { preload: true }
  },
  {
    path: 'callbacks',
    loadChildren: () => import('./routes/callbacks/callbacks.module').then(m => m.CallbacksModule)
  },
  {
    path: 'my-orgs',
    loadChildren: () => import('./routes/my-org/org.module').then(m => m.MyOrgModule),
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  },
  {
    path: 'my-mems',
    loadChildren: () => import('./routes/my-mem/my-mem.module').then(m => m.MyMemModule),
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  },
  {
    path: 'my-events',
    loadChildren: () => import('./routes/my-event/event.module').then(m => m.MyEventModule),
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  },
  {
    path: 'my-tickets',
    loadChildren: () => import('./routes/my-ticket/my-ticket.module').then(m => m.MyTicketModule),
    // canActivate: [AuthGuard],
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  }, {
    path: 'my-donations/:donation_id/payment-update-complete',
    component: DonationPaymentUpdateCompleteComponent
  },
  {
    path: 'my-donations',
    loadChildren: () => import('./routes/my-donation/my-donation.module').then(m => m.MyDonationModule),
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  },


  // // Using My-Orgs/Payouts
  // // {
  // //   path: 'my-payouts',
  // //   loadChildren: () => import('./my-payout/my-payout.module').then(m => m.MyPayoutModule),
  // //   canActivate:[AuthGuardMaintenance],
  // //   canLoad: [CanLoadAuthGuard]
  // // },
  {
    path: 'my-stuff',
    loadChildren: () => import('./routes/my-dash/my-dash.module').then(m => m.MyDashModule),
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  },
  {
    path: 'my-notifs',
    loadChildren: () => import('./routes/my-notif/my-notif.module').then(m => m.MyNotifModule),
    canActivate: [AuthGuardMaintenance],
    canLoad: [CanLoadAuthGuard]
  },
  // {path: 'page-not-found', component: PageNotFoundComponent},
  // {path: 'maintenance', component: MaintenanceComponent},
  // // { path: 'login-error', component: LoginErrorComponent },
  // {path: '**', component: PageNotFoundComponent}
  // // { path: '**', component: DispatchComponent } //'./dispatch/dispatch.module#DispatchModule'
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      // preloadingStrategy: CustomPreloadingStrategy,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      // malformedUriErrorHandler: (error: URIError, urlSerializer: UrlSerializer, url: string) => urlSerializer.parse('/page-not-found')
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
