<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-100">
  <body class="h-full">
  ```
-->
<div class="min-h-screen max-w-full flex flex-col">
  <!--
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-100">
  <body class="h-full">
  ```
-->
  <!-- <p>isLoggedIn$ = {{isLoggedIn$|async}}</p>
  <p>user$ = {{user$|async|json}}</p> -->
  <header class="h-16 sticky top-0 z-50">
    <nav class="bg-primary inset-0 w-full top-0 z-50">
      <div class="container-fluid mx-auto max-w-full px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <a routerLink="/home">
                <img class="h-8 w-auto" [src]="appLogo" [alt]="appName">
              </a>
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <!-- Current: "bg-indigo-700 text-white", Default: "text-white hover:bg-indigo-500 hover:bg-opacity-75" -->
                <!-- <a routerLink="/home" routerLinkActive="active"
                  class="text-white hover:bg-accent  rounded-md px-3 py-2 text-sm font-medium"
                  aria-current="page">HOME</a> -->
                <a routerLink="/events" routerLinkActive="active"
                  class="text-white hover:bg-accent rounded-md px-3 py-2 text-sm font-medium">Events</a>
                <a routerLink="/orgs" routerLinkActive="active"
                  class="text-white hover:bg-accent rounded-md px-3 py-2 text-sm font-medium">Orgs</a>
                <a routerLink="/my-stuff" routerLinkActive="active" 
                  class="text-white hover:bg-accent rounded-md px-3 py-2 text-sm font-medium">My Stuff</a>

                <a routerLink="/home/getting-started" routerLinkActive="active"
                  class="text-white hover:bg-accent rounded-md px-3 py-2 text-sm font-medium">Support</a>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <button type="button" *ngIf="!(isLoggedIn$|async)" (click)="userService.login()"
              class="rounded-md  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-primary hover:ring-accent hover:text-accent">Login</button>

            <div class="ml-4 flex items-center md:ml-6" *ngIf="(isLoggedIn$|async)">
              <button type="button" (click)="showNotifs()"
                class="rounded-full bg-transparent p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-accent-600">
                <span class="sr-only">View notifications</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
              </button>

              <!-- Profile dropdown -->
              <!-- <div class="relative inline-block text-left">
                <div>
                  <button type="button" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" id="menu-button" aria-expanded="true" aria-haspopup="true">
                    Options
                    <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                    </svg>
                  </button>
                </div>
                <div class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="px-4 py-3" role="none">
                    <p class="text-sm" role="none">Signed in as</p>
                    <p class="truncate text-sm font-medium text-gray-900" role="none">{{'tom@example.com'}}</p>
                  </div>
                  <div class="py-1" role="none">
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
                  </div>
                  <div class="py-1" role="none">
                    <form method="POST" action="#" role="none">
                      <button type="submit" class="block w-full px-4 py-2 text-left text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
                    </form>
                  </div>
                </div>
              </div> -->
              
              <div class="relative ml-3">
                <div>
                  <button type="button"
                    class="rounded-full bg-transparent p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-accent-600"
                    id="user-menu-button" aria-expanded="false" aria-haspopup="true"
                    [matMenuTriggerFor]="toolbarUserMenu">
                    <span class="sr-only">Open user menu</span>
                    <span *ngIf="(user$|async)?.picture as pic; else noPic">
                      <img class="h-8 w-8 rounded-full" [src]="pic" [alt]="pic">
                    </span>
                    <ng-template #noPic>
                      <svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </ng-template>

                  </button>
                </div>

                <mat-menu #toolbarUserMenu="matMenu">
                  <button mat-menu-item [routerLink]="'/my-stuff'">
                    <mat-icon>dashboard</mat-icon>
                    <span>My Stuff</span>
                  </button>
                  <button mat-menu-item [routerLink]="'/home/user-profile'">
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                  </button>
                  <button mat-menu-item (click)="userService.logout()">
                    <mat-icon>power_settings_new</mat-icon>
                    <span>Logout</span>
                  </button>
                </mat-menu>                
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button type="button" (click)="showMenu = !showMenu"
              class="inline-flex items-center justify-center rounded-md bg-accent p-2 text-lime-200 hover:bg-accent hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-primary"
              aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!-- Menu open: "hidden", Menu closed: "block" -->
              <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <!-- Menu open: "block", Menu closed: "hidden" -->
              <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div class="md:hidden" [ngClass]="showMenu ? 'md:block':'hidden'">
        <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          <!-- <a routerLink="/home" routerLinkActive="active"
            class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium"
            aria-current="page">HOME</a> -->
          <a routerLink="/events" routerLinkActive="active" (click)="showMenu = !showMenu"
            class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium">Events</a>
          <a routerLink="/orgs" routerLinkActive="active" (click)="showMenu = !showMenu"
            class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium">Orgs</a>
          <!-- <a routerLink="/my-stuff" routerLinkActive="active" *ngIf="isLoggedIn$|async;else notLoggedInMobile" (click)="showMenu = !showMenu"
            class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium">My Stuff</a>
            <ng-template #notLoggedInMobile>
              <a routerLink="/my-stuff" routerLinkActive="active"  (click)="userService.login(); showMenu = !showMenu"
              class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium">Login</a>
            </ng-template> -->
    
            <a routerLink="/home/getting-started" routerLinkActive="active" (click)="showMenu = !showMenu"
            class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium">Support</a>
        </div>
        <div class="border-t border-primary pb-3 pt-4" *ngIf="user$|async; else noUserMobile">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img class="h-8 w-8 rounded-full" [src]="(user$|async)?.picture" alt="">
            </div>
            <div class="ml-3">
              <div class="text-xl font-semibold text-white"> {{(user$|async)?.name}}</div>
              <div class="text-sm font-light text-white">{{(user$|async)?.email}}</div>
            </div>
            <button type="button" (click)="showNotifs(); showMenu = !showMenu"
              class="ml-auto flex-shrink-0 rounded-full p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-primary">
              <span class="sr-only">View notifications</span>
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
              </svg>
            </button>
          </div>
          <div class="mt-3 space-y-1 px-2">
            <a [routerLink]="['my-stuff']" class="block rounded-md px-3 py-2 text-base text-white hover:bg-accent" (click)="showMenu = !showMenu">
              My Stuff</a>
            <a [routerLink]="['home','user-profile']" class="block rounded-md px-3 py-2 text-base  text-white hover:bg-accent" (click)="showMenu = !showMenu">
              Settings</a>
            <a class="block rounded-md px-3 py-2 text-base text-white hover:bg-accent cursor-pointer" (click)="userService.logout(); showMenu = !showMenu" >
              Sign out</a>
          </div>
        </div>
        <ng-template #noUserMobile>
          <a routerLink="/my-stuff" routerLinkActive="active"  (click)="userService.login(); showMenu = !showMenu"
          class="text-white hover:bg-accent block rounded-md px-3 py-2 text-sm font-medium">Login</a>
        </ng-template>

      </div>
    </nav>
  </header>

  <!-- <header class="bg-white shadow-sm">
    <div class="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
      <h1 class="text-lg font-semibold leading-6 text-gray-900">Dashboard</h1>
    </div>
    py-6 px-6 lg:px-12
  </header> -->
  <main>
    <div class="mx-auto max-w-full sm:p-3   relative z-10">
      <!-- <div class="mx-auto max-w-7xl px-6 lg:px-8">  for smaller center screen--> 
      <router-outlet class='relative flex flex-grow'></router-outlet>
      <!-- </div>   -->
    </div>

  </main>
</div>